import React from "react"

import Layout from "../components/layout"
import SEO from "../components/Seo/seo"
import HeroScene from "../components/PageParts/404/HeroScene"

const NotFoundPage = props => (
  <Layout location={props.location.pathname}>
    <SEO title="404: Not found" location={props.location.pathname} />
    <HeroScene />
  </Layout>
)

export default NotFoundPage
