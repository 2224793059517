import React from "react"
import styled from "styled-components"
import { Link } from "gatsby"
import MainTitle from "../../Shared/MainTitle"
import { fontSizer, buttonTwo } from "../../../Utilities/"
import MountainOneSvg from "../../../assets/h-mountain1.svg"
import MountainThreeSvg from "../../../assets/h-mountain3.svg"
import MountainFourSvg from "../../../assets/h-mountain4.svg"
import TreeOneSvg from "../../../assets/h-tree1.svg"
import CloudOneSvg from "../../../assets/h-cloud1.svg"
import CloudTwoSvg from "../../../assets/h-cloud2.svg"
import CloudThreeSvg from "../../../assets/h-cloud3.svg"
import SunSvg from "../../../assets/h-sun.svg"
import WhiteCloudSvg from "../../../assets/cloud-white.svg"

const HeaderSceneStyled = styled.div`
  position: relative;
  width: 100%;
  height: 100vh;
  min-height: 40rem;
  max-height: 40rem;
  background: linear-gradient(
    168deg,
    #fff,
    #fcfdfd 7%,
    #f4f6f7 20%,
    #e6ebee 32%,
    #d2dbe0 42%,
    #b8c7cf 50%,
    #98aeba 60%,
    #7390a0 65%,
    #476e83 85%,
    #174862 90%,
    #003653
  );
  overflow: hidden;

  @media (min-width: 768px) {
    height: 100vh;
    min-height: 55rem;
    max-height: 55rem;
    background: linear-gradient(
      168deg,
      #fff,
      #fcfdfd 24%,
      #f4f6f7 37%,
      #e6ebee 49%,
      #d2dbe0 58%,
      #b8c7cf 67%,
      #98aeba 75%,
      #7390a0 83%,
      #476e83 90%,
      #174862 97%,
      #003653
    );
  }
`

const HeaderTitle = styled.div`
  position: absolute;
  bottom: 19rem;
  right: 0;
  left: 0;
  z-index: 100;
  text-align: center;

  @media (min-width: 370px) {
    bottom: 27.5rem;
  }

  @media (min-width: 768px) {
    bottom: 10vw;
    right: 53.5vw;
    left: auto;
  }

  @media (min-width: 1025px) {
    bottom: 14vw;
  }

  h2 {
    ${fontSizer(2.2, 4, 76.8, 160, 2.6)}
  }

  a {
    ${buttonTwo};
  }
`

const MountainOneStyled = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  position: absolute;
  bottom: -1rem;
  left: -12.5vw;
  width: 55vw;
  height: 100%;
  z-index: 3;

  @media (min-width: 1025px) {
    max-width: 85rem;
  }

  @media screen and (-ms-high-contrast: active),
    screen and (-ms-high-contrast: none) {
    top: 17%;
  }

  svg {
    width: 100%;
    max-width: 100%;
  }
`

const MountainTwoStyled = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  position: absolute;
  bottom: -1rem;
  left: 10vw;
  width: 45vw;
  height: 100%;
  z-index: 2;

  @media (min-width: 1025px) {
    max-width: 70rem;
  }

  @media screen and (-ms-high-contrast: active),
    screen and (-ms-high-contrast: none) {
    top: 17%;
  }

  svg {
    width: 100%;
    max-width: 100%;
  }
`

const MountainThreeStyled = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  position: absolute;
  bottom: -1rem;
  right: 15vw;
  width: 38.5vw;
  height: 100%;
  z-index: 5;

  @media (min-width: 1025px) {
    max-width: 60rem;
  }

  @media screen and (-ms-high-contrast: active),
    screen and (-ms-high-contrast: none) {
    top: 17%;
  }

  svg {
    width: 100%;
    max-width: 100%;
  }
`

const MountainFourStyled = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  position: absolute;
  bottom: -1rem;
  right: 2vw;
  width: 32vw;
  height: 100%;
  z-index: 4;

  @media (min-width: 1025px) {
    max-width: 50rem;
  }

  @media screen and (-ms-high-contrast: active),
    screen and (-ms-high-contrast: none) {
    top: 17%;
  }

  svg {
    width: 100%;
    max-width: 100%;
  }
`

const MountainFiveStyled = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  position: absolute;
  bottom: -1rem;
  right: 34vw;
  width: 37.5vw;
  height: 100%;
  z-index: 6;

  @media (min-width: 1025px) {
    max-width: 58rem;
  }

  @media screen and (-ms-high-contrast: active),
    screen and (-ms-high-contrast: none) {
    top: 17%;
  }

  svg {
    width: 100%;
    max-width: 100%;
  }
`

const WhiteCloudWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  position: absolute;
  top: 0;
  bottom: -4rem;
  left: 0rem;
  width: 115vw;
  z-index: 10;

  @media (min-width: 375px) {
    bottom: -5rem;
  }

  @media (min-width: 768px) {
    bottom: -10rem;
  }

  @media (min-width: 1025px) {
    bottom: -15rem;
    max-width: 200rem;
  }

  @media (min-width: 1850px) {
    bottom: -30rem;
    max-width: 275rem;
  }

  @media screen and (-ms-high-contrast: active),
    screen and (-ms-high-contrast: none) {
    bottom: -45rem;
  }

  .cloud-inside {
    @media screen and (-ms-high-contrast: active),
      screen and (-ms-high-contrast: none) {
      width: 100%;
      height: 100%;
      bottom: -45rem;
    }
  }

  svg {
    width: 100%;
    max-width: 100%;
    height: 100%;
  }
`

const SunWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  position: absolute;
  bottom: 17vw;
  right: 42.5%;
  width: 17vw;
  height: 17vw;
  z-index: 1;

  @media (min-width: 1025px) {
    max-width: 27.5rem;
  }

  svg {
    width: 100%;
    max-width: 100%;
  }
`

const CloudOneStyles = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  position: absolute;
  top: 5%;
  left: 0;
  width: 70vw;
  max-width: 97rem;
  height: 50vw;
  max-height: 70rem;
  z-index: 25;

  svg {
    width: 100%;
    max-width: 100%;
  }
`

const CloudTwoStyles = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  position: absolute;
  top: 35%;
  right: 25%;
  width: 20vw;
  max-width: 28rem;
  height: 20vw;
  max-height: 28rem;
  z-index: 50;

  svg {
    width: 100%;
    max-width: 100%;
  }
`

const CloudThreeStyles = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  position: absolute;
  bottom: 10%;
  right: 5%;
  width: 20vw;
  max-width: 28rem;
  height: 20vw;
  max-height: 28rem;
  z-index: 50;

  svg {
    width: 100%;
    max-width: 100%;
  }
`

const TreeOneStyles = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  position: absolute;
  bottom: -1rem;
  right: 5%;
  width: 5vw;
  height: 100%;
  z-index: 7;

  @media (min-width: 1025px) {
    max-width: 8.1rem;
  }

  @media screen and (-ms-high-contrast: active),
    screen and (-ms-high-contrast: none) {
    top: 43%;
  }

  svg {
    width: 100%;
    max-width: 100%;
  }
`

const TreeTwoStyles = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  position: absolute;
  bottom: -1rem;
  right: 15%;
  width: 7.5vw;
  height: 100%;
  z-index: 7;

  @media (min-width: 1025px) {
    max-width: 12.2rem;
  }

  @media screen and (-ms-high-contrast: active),
    screen and (-ms-high-contrast: none) {
    top: 37%;
  }

  svg {
    width: 100%;
    max-width: 100%;
  }
`

const TreeThreeStyles = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  position: absolute;
  bottom: -1rem;
  right: 20.5%;
  width: 7vw;
  height: 100%;
  z-index: 7;

  @media (min-width: 1025px) {
    max-width: 11.2rem;
  }

  @media screen and (-ms-high-contrast: active),
    screen and (-ms-high-contrast: none) {
    top: 34%;
  }

  svg {
    width: 100%;
    max-width: 100%;
  }
`

const HeaderScene = () => {
  return (
    <HeaderSceneStyled>
      <HeaderTitle>
        <MainTitle title="404 Page Not Found" />
        <div className="link-back-home">
          <Link to="/">Back Home</Link>
        </div>
      </HeaderTitle>

      <div>
        <CloudOneStyles>
          <CloudThreeSvg />
        </CloudOneStyles>
        <CloudTwoStyles>
          <CloudOneSvg />
        </CloudTwoStyles>
        <CloudThreeStyles>
          <CloudTwoSvg />
        </CloudThreeStyles>
      </div>

      <div>
        <MountainOneStyled>
          <MountainOneSvg />
        </MountainOneStyled>

        <MountainTwoStyled>
          <MountainFourSvg />
        </MountainTwoStyled>

        <MountainFiveStyled>
          <MountainOneSvg />
        </MountainFiveStyled>

        <MountainThreeStyled>
          <MountainThreeSvg />
        </MountainThreeStyled>

        <MountainFourStyled>
          <MountainFourSvg />
        </MountainFourStyled>
      </div>

      <div>
        <TreeOneStyles>
          <TreeOneSvg />
        </TreeOneStyles>
        <TreeTwoStyles>
          <TreeOneSvg />
        </TreeTwoStyles>
        <TreeThreeStyles>
          <TreeOneSvg />
        </TreeThreeStyles>
      </div>

      <div>
        <SunWrapper>
          <SunSvg />
        </SunWrapper>
      </div>
      <div>
        <WhiteCloudWrapper>
          <div className="cloud-inside">
            <WhiteCloudSvg />
          </div>
        </WhiteCloudWrapper>
      </div>
    </HeaderSceneStyled>
  )
}

export default HeaderScene
